import React from 'react';
import { AutoFocusedForm, PhotoField, required, SelectField, TextField } from 'components';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { MutableButton } from 'ui/MutableButton';
import { RedButton } from 'components/Buttons';
import { Field } from 'react-final-form';
import { ScreenStoryPropsFragment, StoryScreenTheme } from '../../../../schema';
import { useScreensStorage } from '../useScreensStorage';
import { useParams } from 'react-router-dom';
import { useIsEditStoryStorage } from '../useIsEditStoryStorage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 500,
    },
    gridItem: {
      paddingBottom: '1rem',
    },
    header: {
      fontWeight: 'bolder',
    },
    content: {
      display: 'flex',
      gap: '15px',
      margin: '10px',
    },
    actions: {
      justifyContent: 'flex-end',
    },
    accessButton: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
      '&:hover': {
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
      },
    },
  })
);

interface UpdateNameStoryFormProps {
  onClose: () => void;
  onUpdate: (values: ScreenStoryPropsFragment) => void;
  setIsStoryEdited: (isEdited: boolean) => void;
  cardIndex: number | null;
  screenCards: ScreenStoryPropsFragment[];
}

export const EditScreenForm = ({
  onClose,
  onUpdate,
  setIsStoryEdited,
  cardIndex,
  screenCards,
}: UpdateNameStoryFormProps) => {
  const styles = useStyles();
  const { storyId } = useParams<{ storyId: string }>();
  const { updateIsEditStoryStorageHistory } = useIsEditStoryStorage({ id: storyId });
  const { screensStorageHistory, updateScreensStorageHistory } = useScreensStorage();

  const handleSubmitForm = async (values: ScreenStoryPropsFragment) => {
    const storedData = screensStorageHistory;

    let data: ScreenStoryPropsFragment[];

    if (storedData) {
      data = storedData;
    } else {
      data = screenCards;
    }

    if (cardIndex === null) {
      data.push(values);
    } else {
      data[cardIndex] = values;
    }
    await updateScreensStorageHistory(data);
    onUpdate(values);
    setIsStoryEdited(true);
    updateIsEditStoryStorageHistory(true);
    onClose();
  };

  const initialValues = screenCards[cardIndex!] ||
    screensStorageHistory?.[cardIndex!] || {
      imageId: '',
      title: '',
      text: '',
      buttonTitle: '',
      buttonUrl: '',
      theme: StoryScreenTheme.Dark,
    };

  return (
    <AutoFocusedForm
      onSubmit={handleSubmitForm}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles.root}>
              <CardHeader title={`Редактор экрана`} />
              <Divider />
              <CardContent className={styles.content}>
                <Grid container spacing={4}>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="imageId"
                      component={PhotoField}
                      url={initialValues.image?.url}
                      isIncludedVideo={true}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={styles.gridItem}>
                    <Field
                      name="title"
                      label="Заголовок экрана"
                      component={TextField}
                      multiline
                      rows={3}
                      rowsMax={15}
                      type="string"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={styles.gridItem}>
                    <Field
                      name="text"
                      label="Текст экрана"
                      component={TextField}
                      multiline
                      rows={5}
                      rowsMax={50}
                      type="string"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={styles.gridItem}>
                    <Field
                      name="buttonTitle"
                      label="Заголовок кнопки"
                      component={TextField}
                      type="string"
                      validate={values.buttonUrl ? required : undefined}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} className={styles.gridItem}>
                    <Field
                      name="buttonUrl"
                      label="Адрес перехода кнопки"
                      component={TextField}
                      type="string"
                      validate={values.buttonTitle ? required : undefined}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Field
                      name="theme"
                      label="Тема экрана истории"
                      component={SelectField}
                      type="string"
                    >
                      <MenuItem value={StoryScreenTheme.Dark}>Темная</MenuItem>
                      <MenuItem value={StoryScreenTheme.Light}>Светлая</MenuItem>
                    </Field>
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions className={styles.actions}>
                <MutableButton
                  className={styles.accessButton}
                  loading={false}
                  color="primary"
                  disabled={pristine}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Сохранить изменения
                </MutableButton>
                <RedButton onClick={onClose} size="medium" type="button" variant="contained">
                  Отменить
                </RedButton>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
